<template>
  <div>
    <column-picker
      :selected-col-id.sync="computedCol"
      :filters="filters"
      :label="$t(`t.Selector`)"
      :attach="false"
      :hide-details="true"
      :show-icon="true"
      :rules="required"
      avg-cols-only
      currency-disabled
    />
  </div>
</template>

<script>
export default {
  components: {
    ColumnPicker: () => import('@/components/column-picker')
  },
  computed: {
    computedCol: {
      get () { return this.dataCol },
      set (v) {
        this.dataCol = v
        this.dataDocumentEmit()
      }
    },
    filters () {
      return { 'document-types': [[`${this.targetDocTy}`], []] }
    }
  },
  data () {
    return {
      dataCol: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      required: [v => !!v || this.$t('t.IsRequired')]
    }
  },
  inheritAttrs: false,
  methods: {
    emitDocument () {
      const doc = { col: this.dataCol }

      if (!this.lodash.isEqual(doc, this.document)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    }
  },
  props: {
    document: Object,
    targetDocTy: String
  },
  watch: {
    document: {
      handler (v) {
        const s = this.lodash.cloneDeep(v)
        this.dataCol = s?.col || null
        this.dataDocumentEmit()
      },
      immediate: true
    }
  }
}
</script>
